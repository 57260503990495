import '../../assets/plugins/react-charts.css'
import './DashboardPB.css'
import ApiInvoker from '../../api/ApiInvoker';
import config from '../../config/config.js';

import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from "powerbi-client";
import Cookies from 'universal-cookie';

const dateFormat = 'DD/MM/YYYY'
const reportType = 'DIABETIC';

class DashboardDiabetic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyId: 1,
      isNewVersion: false,
      loading: true,
    }
    this.callReport();

  }

  callReport() {
    let companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    let companyPartnerShip = -1;
    if (localStorage.getItem('company_partnership_id') != null) {
      companyPartnerShip = localStorage.getItem('company_partnership_id');
    }
    ApiInvoker.getReportByCompanyAndPartnetship(companyId, companyPartnerShip, reportType, data => {
      // Process response.
      this.setState({
        username:  data.userPBI,
        password: data.passPBI,
        datasetId: data.datasetId,
        reportId: data.reportId
      }, () => {
        this.callLoginPB();
      });

    }, error => {
      this.setState({isNewVersion: false, loading: false});
    });
  }
  callLoginPB() {

    const requestOptions = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        urlloginpb: config.powerbi_urllogin,
        username: this.state.username,
        password: this.state.password,
        clientid: config.powerbi_client_id,
        granttype: 'password',
        scope: config.powerbi_scope,
        resource: config.powerbi_resource
      }),
    };

    fetch('/api/pb/login', requestOptions)
      .then(response => (response.json()))
      .then(data => {
        if (!data.error) {
          this.setState({ token: data.access_token }, () => {
            this.callApiPB();
          })
        } else {
          this.setState({ error: data.error, isNewVersion: false,loading: false });
          console.log('callLoginPB fail in Node ../api/pb/login: ', data);
        }
      })
      .catch((error) => {
        this.setState({ error: error,isNewVersion: false,loading: false });
        console.error('callLoginPB Error:', error);
      });
  }

  callApiPB() {

    const requestOptions = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        urlapipb: config.powerbi_urlreport,
        datasets: this.state.datasetId,
        reportid: this.state.reportId,
        token: this.state.token
      }),
    };

    fetch('/api/pb/api', requestOptions)
      .then(response => (response.json()))
      .then(data => {
        if (!data.error) {
          this.setState({reportToken: data.token, isNewVersion: true, error: null, loading: false});
        } else {
          console.log('callApiPB fail in Node ../api/pb/api: ', data);
          this.setState({error: data.error, isNewVersion: false, loading: false});
        }
      })
      .catch((error) => {
        console.error('callApiPB Error:', error);
        this.setState({error: error, isNewVersion: false, loading: false});
      });

    console.log('Finish callApiPB');
  }



  componentDidMount() {
    this.setState({
      companyId: localStorage.getItem('itlg_default_company_id'),
    })
  }

  render() {
    const { t } = this.props
    // ya cambiada
    let DASHBOARD_LINK =
      'https://app.powerbi.com/reportEmbed?reportId=c6e0e205-1346-4d4c-9791-9c7351dffbe3&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3'

    var companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    switch (companyId) {
      case 27:
        DASHBOARD_LINK = "https://app.powerbi.com/reportEmbed?reportId=f904d1ba-27c5-46b0-b88d-acee87f9df9d&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3";
        break;
   }

   if (this.state.isNewVersion) {

    if (this.state.reportToken != null) {
      const cookies = new Cookies();
      const lang = cookies.get("lang") || 'es';

      return (
        <div style={{ width: '100%', height: '100%' }}>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: this.state.reportId,
              accessToken: this.state.reportToken,
              tokenType: models.TokenType.Embed,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                layoutType: models.LayoutType.Custom,
                displayOption: models.DisplayOption.FitToWidth,
                localeSettings: {
                  language: config.lang_pb[lang]
                }
              }
            }}
            cssClassName={"report-style-class"}
          />
        </div>
      );
    } else {
      return (<div>Loading</div>)
    }

  } else {

    return (
      <div style={{ width: '100%', height: '100%' }}>
       <div className="div">
        <iframe
          width="100%"
          height={window.innerHeight}
          src={DASHBOARD_LINK}
          frameborder="0"
          allowFullScreen="true"
        ></iframe>{' '}
      </div></div>
    );
  }
}
}
export default withTranslation()(DashboardDiabetic)
