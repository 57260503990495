import '../../assets/plugins/react-charts.css';
import './DashboardPB.css';

import React, { Component } from 'react';

import ApiInvoker from '../../api/ApiInvoker';
import { PowerBIEmbed } from 'powerbi-client-react';
import PropTypes from 'prop-types';
import config from '../../config/config';
import { models } from 'powerbi-client';
import { withTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

const reportType = 'FINANCIER';

class DashboardFinancier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId : 0,
      dashboards: [
        "https://app.powerbi.com/reportEmbed?reportId=7d7c9cb1-acc7-4872-ab1c-4d795744f7d6&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22",
      ],
      isNewVersion: false,
      loading: true,
    }
  }
  
  componentDidMount() {
    const companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    this.setState({ companyId });
    this.callReport();
  }

  callReport() {
    let companyPartnerShip = -1;
    if (localStorage.getItem('company_partnership_id') != null) {
      companyPartnerShip = localStorage.getItem('company_partnership_id');
    }
    ApiInvoker.getReportByCompanyAndPartnetship(this.state.companyId, companyPartnerShip, reportType, data => {
      // Process response.
      this.setState({
        username:  data.userPBI,
        password: data.passPBI,
        datasetId: data.datasetId,
        reportId: data.reportId,
      }, () => {
        this.callLoginPB();
      });
    }, (error) => {
      console.log('** Error getReportByCompanyAndPartnetship', error);
      this.setState({ isNewVersion: false, loading: false });
    });
  }
  
  callLoginPB() {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        urlloginpb: config.powerbi_urllogin,
        username: this.state.username,
        password: this.state.password,
        clientid: config.powerbi_client_id,
        granttype: 'password',
        scope: config.powerbi_scope,
        resource: config.powerbi_resource,
      }),
    };

    fetch('/api/pb/login', requestOptions)
      .then(response => (response.json()))
      .then(data => {
        if (!data.error) {
          this.setState({ token: data.access_token }, () => {
            this.callApiPB();
          })
        } else {
          this.setState({ error: data.error, isNewVersion: false,loading: false });
          console.log('callLoginPB fail in Node ../api/pb/login: ', data);
        }
      })
      .catch((error) => {
        this.setState({ error: error,isNewVersion: false,loading: false });
        console.error('callLoginPB Error:', error);
      });
  }

  callApiPB() {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        urlapipb: config.powerbi_urlreport,
        datasets: this.state.datasetId,
        reportid: this.state.reportId,
        token: this.state.token
      }),
    };

    fetch('/api/pb/api', requestOptions)
      .then(response => (response.json()))
      .then(data => {
        if (!data.error) {
          this.setState({ reportToken: data.token, isNewVersion: true, error: null, loading: false });
        } else {
          console.log('callApiPB fail in Node ../api/pb/api: ', data);
          this.setState({ error: data.error, isNewVersion: false, loading: false });
        }
      })
      .catch((error) => {
        console.error('callApiPB Error:', error);
        this.setState({error: error, isNewVersion: false, loading: false});
      });
  }

  getDashboardLink() {
    const { companyId, dashboards } = this.state;
    const companyPartnerShip = localStorage.getItem('company_partnership_id');
    return companyId === 11 && !companyPartnerShip ? dashboards[0] : '';
  }

  render() {
    const { loading, isNewVersion, reportId, reportToken } = this.state;
    
    if (loading) {
      return <div/>
    }
    
    if (!isNewVersion) {
      const DASHBOARD_LINK = this.getDashboardLink();   
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            title="Tablero Financiador General"
            width="100%"
            height={window.innerHeight} 
            src={DASHBOARD_LINK}
            allowFullScreen={true}
            style={{ border: '0' }}
          ></iframe>{' '}
        </div>
      )
    }

    if (reportToken != null) {
      const cookies = new Cookies();
      const lang = cookies.get("lang") || 'es';
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: reportId,
              accessToken: reportToken,
              tokenType: models.TokenType.Embed,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                layoutType: models.LayoutType.Custom,
                displayOption: models.DisplayOption.FitToWidth,
                localeSettings: {
                  language: config.lang_pb[lang]
                }
              }
            }}
            cssClassName="report-style-class"
          />
        </div>
      );
    }
    return (<div>{this.props.t('table.loading')}</div>)
  }
}

DashboardFinancier.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(DashboardFinancier);
